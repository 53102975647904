import React, { useState, useEffect, useRef } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useNavigate } from 'react-router';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import jwt_decode from 'jwt-decode';
import Divider from '@material-ui/core/Divider';
import { Popover } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useTranslation, Trans } from 'react-i18next';
import CancelIcon from '@images/IconHeader/cancel.svg';
import ConfirmIcon from '@images/icons/svg/confirm.svg';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useApolloClient, useMutation } from '@apollo/client';
import SettingsIcon from '@material-ui/icons/Settings';
import StatsIcon from '@images/icons/svg/stats.svg';
import DownloadIcon from '@images/IconHeader/download.svg';
import LaptopIcon from '@images/icons/svg/laptopSmall.svg';
import { useSnackbar } from 'notistack';
import { clearCachePersist } from 'storage/dataDbCache';
import { REVOKE_TOKEN } from 'mutation/authentication';
import LinkIcon from '@material-ui/icons/Link';
import { MeType } from 'models/me';
import { useQueryCached } from './utils/graphql';
import { CACHE_AND_NETWORK } from '../lib/cache';
import { UNITS_VALUES } from './utils/units';
import {
  UPDATE_USERPREFERENCES,
} from '../mutation/user';
import MySettings from './UserSettings';
import { SideDrawer } from './layout/SideDrawer';
import BaseButton from './form/BaseButton';
import { AVAILABLE_UNITS, USER } from '../query/user';
import { APP_STATES, AppState, useGlobalState } from '../lib/global';
import { SNACKBAR_TIMEOUT } from './constants';
import DialogBox from './DialogBox';
import { globalEventsChannel } from '../lib/events';
import { getOldUiUrl } from './utils/utils';
import { colorsPalette } from './utils/colors';
import { client as workerApolloClient } from '../workers/graphql';

type PropsIn = {
  me?: MeType,
  myUnits: object,
  userInfo: {
    lastName: string,
    firstName: string,
    email: string,
    userLanguage: string,
    userroleSet: [],
    forceUom?: boolean,
  }
  disableMyDocuments?: boolean;
  disableMyStats?: boolean;
}

const defaultUserUnits = {
  speed: 'km/h',
  power: 'W/kg',
  energy: 'J/kg',
  percentage: '%',
  temperature: 'C',
};

export const setInitialUserUnits = (uu) => {
  if (uu) {
    return {
      speed: UNITS_VALUES[uu.speed],
      power: UNITS_VALUES[uu.power],
      energy: UNITS_VALUES[uu.energy],
      percentage: UNITS_VALUES[uu.percentage],
      temperature: UNITS_VALUES[uu.temperature],
    };
  }
  return defaultUserUnits;
};

const checkUnitsChange = (newUnits, oldUnits) => newUnits.speed !== oldUnits.speed
    || newUnits.power !== oldUnits.power
    || newUnits.energy !== oldUnits.energy
    || newUnits.temperature !== oldUnits.temperature;

export const formatLanguage = (u: string) => u?.toLowerCase().replace('_', '-');

export const handleLogout = (path = '/') => {
  localStorage.removeItem('exelio_token');
  localStorage.removeItem('exelio_refresh_token');
  localStorage.removeItem('exelio_refresh_token_exp');
  window.location.replace(path);
};

export default function (props: PropsIn) {
  const {
    me, myUnits, userInfo, disableMyDocuments, disableMyStats,
  } = props;
  const currentUser = me?.id;
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [contentOpen, setContentOpen] = React.useState(false);
  const [logoutConfirm, setLogoutConfirm] = React.useState(false);

  const [defaultUnits, setDefaultUnits] = useState(myUnits);
  const [userUnits, setUserUnits] = useState(myUnits);

  const [arrayUnits, setArrayUnits] = useState<null | {key: string, value: any }[]>(null);

  const prevUserLanguage = formatLanguage(userInfo?.userLanguage);

  const [userLanguage, setUserLanguage] = useState(formatLanguage(prevUserLanguage));
  const [colorPalette, setColorPalette] = useState(colorsPalette);
  const [userName, setUserName] = useState<{lastName: string, firstName: string}>({
    lastName: userInfo?.lastName || '',
    firstName: userInfo?.firstName || '',
  });
  const [forceUom, setForceUom] = useState(userInfo?.forceUom || false);

  const [unitsRefetch, setUnitsRefetch] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [appState] = useGlobalState(AppState);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const client = useApolloClient();
  const refreshToken = localStorage.getItem('exelio_refresh_token');

  const [revokeToken] = useMutation(REVOKE_TOKEN, {
    onCompleted() {
      handleLogout('/login');
    },
  });

  const { data: userData } = useQueryCached(USER, {
    variables: {
      id: currentUser,
    },
  });
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'template-selector' : undefined;

  const { data: unitsData } = useQueryCached(AVAILABLE_UNITS, {
    onCompleted: (units) => {
      const convertedUnits = Object.entries(units?.res?.availableUserUnits).map(([k, v]) => ({
        key: k,
        value: v,
      }));
      setArrayUnits(convertedUnits);
    },
    ...CACHE_AND_NETWORK,
  });

  const [updateUserPreferences] = useMutation(UPDATE_USERPREFERENCES, {
    onError: () => enqueueSnackbar(t('dialog.general.somethingWentWrong', 'something went wrong'), {
      variant: 'error',
      autoHideDuration: SNACKBAR_TIMEOUT,
    }),
  });

  useEffect(() => {
    if (unitsRefetch) {
      client.cache.reset(); // clear Apollo client cache
      clearCachePersist().then(() => { // clear IndexedDB persisting cache
        console.log('cache cleared successfully');
        setTimeout(() => (location.reload()), 1500); // refresh page
      })
        .catch((error) => {
          console.error('error', error);
        });
    }
  }, [unitsRefetch]);

  const loginToAdmin = () => {
    const oldUiUrl = getOldUiUrl();
    const adminUrl = oldUiUrl.concat('admin/');
    window.location.replace(adminUrl);
  };

  return (
    <>
      <BaseButton
        tooltipText={t('tooltip.account', 'account')}
        aria-describedby={id}
        onClick={handleClick}
        isIconButton
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="black" fillOpacity="0.54" />
        </svg>
      </BaseButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box bgcolor="white" className="account-menu">
          <List dense>
            <ListItem>
              <span className="account-email">{jwt_decode(localStorage.getItem('exelio_token')).email || 'unknown'}</span>
            </ListItem>
            <Divider />

            {
              !(appState !== APP_STATES.normal)
              && (
              <>
                <ListItem
                  disabled={disableMyDocuments}
                  onClick={() => {
                    navigate('/exports');
                    handleClose();
                  }}
                  button
                  className="account-menu--item"
                >
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {t('header.account.myDocuments', 'my documents')}
                  </ListItemText>
                </ListItem>

                <ListItem
                  disabled={disableMyStats}
                  onClick={() => {
                    handleClose();
                    navigate('/my_stats');
                  }}
                  button
                  className="account-menu--item"
                >
                  <ListItemIcon>
                    <StatsIcon style={{ paddingRight: '8px' }} />
                  </ListItemIcon>
                  <ListItemText>
                    {t('header.account.myStats', 'my stats')}
                  </ListItemText>
                </ListItem>

                <ListItem
                  onClick={() => {
                    setContentOpen(true);
                    handleClose();
                  }}
                  button
                  className="account-menu--item"
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {t('header.account.myUser', 'my user')}
                  </ListItemText>
                </ListItem>

                <ListItem
                  onClick={() => {
                    navigate('/welcome');
                    handleClose();
                  }}
                  button
                  className="account-menu--item"
                >
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText>
                    welcome page
                  </ListItemText>
                </ListItem>
              </>
              )
            }

            { userData?.res?.isStaff
              && (
                <ListItem
                  onClick={() => {
                    loginToAdmin();
                    handleClose();
                  }}
                  button
                  className="account-menu--item"
                >
                  <ListItemIcon>
                    <LaptopIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {t('header.account.admin', 'admin')}
                  </ListItemText>
                </ListItem>
              )}

            <ListItem onClick={() => setLogoutConfirm(true)} button className="account-menu--item account-menu--important">
              <ListItemIcon>
                <ExitToAppRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                {t('header.account.logout', 'logout')}
              </ListItemText>
            </ListItem>

          </List>
        </Box>
      </Popover>
      <SideDrawer
        title={t('header.account.myUser', 'my user')}
        open={contentOpen}
        setOpen={setContentOpen}
        actionsLeft={[
          <BaseButton
            key="cancel"
            onClick={() => {
              setContentOpen(false);
              setUserUnits(() => ({ ...defaultUnits }));
              setUserLanguage(formatLanguage(userInfo?.userLanguage));
            }}
            startIcon={<CancelIcon />}
          >
            {t('stats.drawer.button.cancel', 'cancel')}
          </BaseButton>,
        ]}
        actionsRight={[
          <BaseButton
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            marginRight
            key="units-save"
            isValidation
            onClick={() => {
              updateUserPreferences({
                variables: {
                  ...userUnits,
                  preferredLanguage: userLanguage,
                  colorPalette: colorPalette?.toUpperCase(),
                  userId: currentUser,
                  lastName: userName.lastName,
                  firstName: userName.firstName,
                  forceUom,
                },
                onCompleted: (meData) => {
                  if (meData) {
                    if (meData.res?.userunits && checkUnitsChange(meData.res?.userunits, defaultUnits)) {
                      setUnitsRefetch(true);
                    }
                    setDefaultUnits(meData?.res?.userunits);
                    setUserUnits(() => ({ ...(meData?.res?.userunits) }));

                    if (prevUserLanguage !== userLanguage) {
                      globalEventsChannel.emit('onLanguageChange', userLanguage);
                    }
                    enqueueSnackbar(
                      t('user.dialog.userUpdated', 'user updated'),
                      {
                        variant: 'success',
                        autoHideDuration: SNACKBAR_TIMEOUT,
                      },
                    );
                  } else {
                    enqueueSnackbar(
                      t('dialog.general.somethingWentWrong', 'something went wrong'),
                      {
                        variant: 'error',
                        autoHideDuration: SNACKBAR_TIMEOUT,
                      },
                    );
                  }
                  // if user changed the colors palette, reload page to update:
                  if (me?.colorPalette !== colorPalette) {
                    localStorage.setItem('userColorsPalette', String(colorPalette));
                    workerApolloClient.cache.reset();
                    window.__APOLLO_CLIENT__.cache.reset();
                    setTimeout(() => window.location.reload(), 1500);
                  }
                },
              });
              setContentOpen(false);
            }}
          >
            {t('user.drawer.footer.save')}
          </BaseButton>,
        ]}
      >
        { contentOpen
          && (
          <MySettings
            userUnits={userUnits}
            setUserUnits={setUserUnits}
            availableUnits={arrayUnits}
            userInfo={userInfo}
            setUserName={setUserName}
            userName={userName}
            userLanguage={userLanguage}
            setUserLanguage={setUserLanguage}
            colorPalette={colorPalette}
            setColorPalette={setColorPalette}
            forceUom={forceUom}
            setForceUom={setForceUom}
          />
          )}
      </SideDrawer>
      <DialogBox
        title={`${t('dialog.title.warning', 'warning')}`}
        buttonAction={t('dialog.action.confirm', 'confirm')}
        buttonColor="secondary"
        icon={<ConfirmIcon />}
        content={(
          <Trans
            i18nKey="user.dialog.confirmLogout"
            defaults="You are going to logout. <br/> Are you sure you want to proceed?"
          />
      )}
        visible={logoutConfirm}
        onClose={() => setLogoutConfirm(false)}
        onClick={() => {
          revokeToken({ variables: { refreshToken } });
        }}
      />
    </>
  );
}
