import React, {
  useState, useEffect, useContext, useMemo, useRef,
  useLayoutEffect,
} from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { subDays } from 'date-fns';
import Header from 'components/layout/Header';
import TitleView from 'components/layout/TitleView';
import SeriesTable from 'components/stats/SeriesTable';
import NewIcon from '@images/IconHeader/new.svg';
import { AppBarContext } from 'components/AppBarContext';
import { ColumnChart } from 'components/charts/ColumnChart';
import {
  DELETE_STATSESSION_ROW,
  STORE_STATISTICS,
} from 'mutation/stats';
import {
  CHECK_STATS_READY, GET_TREND_ID, GET_TREND_SERIES, PING_STATS,
} from 'query/stats';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Box from '@material-ui/core/Box';
import { Row } from 'components/layout/Row';
import { Column } from 'components/layout/Column';
import { Paper } from '@material-ui/core';
import CancelIcon from '@images/IconHeader/cancel.svg';
import SubmitIcon from '@images/icons/svg/submit.svg';
import AddIcon from '@images/IconHeader/add.svg';
import StatsIcon from '@images/icons/svg/addToMyStats.svg';
import PageMessage from 'components/PageMessage';
import Error from 'components/layout/Error';
import useLocalStorage from 'custom_hooks/useLocalStorage';
import { useLazyQueryCached, useQueryCached } from 'components/utils/graphql';
import { MACHINES_RUNNING_COUNT } from 'query/general';
import { Loader } from 'lib/icons';
import { HAS_TEAM_SESSIONS } from 'query/team';
import { CACHE_AND_NETWORK } from 'lib/cache';
import CopyIcon from '@images/icons/svg/copy.svg';
import { globalEventsChannel } from 'lib/events';
import { useSearchParams } from 'react-router-dom';
import BaseButton from '../form/BaseButton';
import { SideDrawer } from '../layout/SideDrawer';
import SeriesInputs2, { SeriesTrendState, defaultMyStats, statsProperties } from './SeriesInputs2';
import {
  categoryOptionsExtractor, copyStatsUrl, kpiOptionsExtractor, tagOptionsExtractor, trendAthleteRoleOptionsExtractor,
} from './utils';
import {
  LoadingContext, MyStatsContext, WebsocketRoomsContext,
} from '../../Authenticator';
import { SNACKBAR_TIMEOUT, SNACKBAR_TIMEOUT_LONGER } from '../constants';
import { ExportButton } from '../form/ExportButton';
import { TooltipKeys, tooltipFormatter } from '../charts/utils';
import {
  getUomTranslation, convertToTitle, rangeOptions,
  dateToUtc,
} from '../utils/utils';
import AddToMyStatsDrawer from './AddToMyStatsDrawer';
import { camelCaser } from '../utils/kpiLabelExtractor';
import useTeamParams from '../../custom_hooks/useTeamParams';

export const EmptyTableRow = {
  period: 'last_7_days',
  startDate: dateToUtc(subDays(new Date(), 6)),
  endDate: dateToUtc(new Date()),
  athletes: null,
  KPI: null,
  categories: null,
  groupingBy: 'session',
  tags: null,
  matchDistance: null,
  matchCycle: null,
  chartType: 'column',
} as SeriesTrendState;

export const matchDistanceOptions = rangeOptions(-8, 9);
export const matchCycleOptions = rangeOptions(1, 10);
export const dataDeletedFromDbErr = "'ObjectTypeOptions' object has no attribute 'model'";

type PropsIn = {
  teamId: string;
  templateId: string;
  userId: string;
};
type athleteRoles = {
    id:string,
    value:string,
    groupBy:string
};

export default function TrendView(props: PropsIn) {
  const { teamId, templateId, userId } = props;
  const { data: hasTeamSessions, loading: loadingCount } = useQueryCached(HAS_TEAM_SESSIONS, { variables: { teamId }, ...CACHE_AND_NETWORK });
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [currentSeries, setCurrentSeries] = useState([null]);
  const [currentValues, setCurrentValues] = useState(EmptyTableRow);
  const [allCurrentValues, setAllCurrentValues] = useState({});
  const [currentValuesCopy, setCurrentValuesCopy] = useState(EmptyTableRow);
  const { myStats, setMyStats } = useContext(MyStatsContext);
  const [trend, setTrend] = useLocalStorage('trend', {
    [teamId]: {
      statsSessionID: null,
      statsSessionRowsID: [],
      loading: false,
      newLoading: false,
      addLoading: false,
      ready: false,
      oldStatsData: false,
    },
  }, myStats?.statsSessionId);
  const [options, setOptions] = useState({
    athleteRoles: [], categories: [], tags: [], kpis: [],
  });
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [addSeries, setAddSeries] = useState(false);
  const [editSerie, setEditSerie] = useState('');
  const [refetchStatus, setRefetchStatus] = useState(0);
  const [contentOpen, setContentOpen] = useState(false);
  const [openAddToMyStats, setOpenAddToMyStats] = useState(false);
  const [disabledButton, setdisabledButton] = useState(true);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [isChartDataLoading, setIsChartDataLoading] = useState(false);
  const chartData = isChartDataLoading ? [null] : currentSeries;
  const [properties, setProperties] = useState<statsProperties>({ title: '', showMyStats: false, showDashboard: false });
  const allowStats = hasTeamSessions?.res;
  const intervalId = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const qpTeamId = searchParams.get('filtersTeam');
  const teamChangedRef = useRef(false);

  useTeamParams();

  const clearPage = () => {
    setCurrentSeries([null]);
    setTableRows([]);
  };

  const clearAndRefetchPage = () => {
    clearPage();
    setRefetchStatus((prev) => prev + 1);
  };

  const resetPageStatus = () => {
    setTrend((prev) => ({
      ...prev,
      [teamId]: {
        ...prev[teamId],
        statsSessionID: null,
        statsSessionRowsID: [],
        loading: false,
        newLoading: false,
        addLoading: false,
        ready: false,
      },
    }));
  };

  useEffect(() => {
    clearAndRefetchPage();
  }, [teamId, myStats?.statsSessionId]);

  // set teamId object in LocalStorage if not present:
  useEffect(() => {
    const storedValue = localStorage.getItem('trend');
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      if (!parsedValue[teamId]) {
        setTrend({
          ...trend,
          [teamId]: {
            statsSessionID: null,
            statsSessionRowsID: [],
            loading: false,
            newLoading: false,
            addLoading: false,
            ready: false,
            oldStatsData: false,
          },
        });
      }
    }
  }, [teamId]);

  useLayoutEffect(() => {
    // update team if filtersTeam in query params:
    if (qpTeamId && qpTeamId !== teamId && !teamChangedRef.current) {
      setTimeout(() => globalEventsChannel.emit('onTeamChange', qpTeamId), 250);
      searchParams.delete('filtersTeam');
      setSearchParams(searchParams);
      teamChangedRef.current = true;
    }
  }, [qpTeamId]);

  // queries to create options:
  const athleteRoleOptions = trendAthleteRoleOptionsExtractor(teamId);
  const categoryOptions = categoryOptionsExtractor(teamId);
  const tagOptions = tagOptionsExtractor(teamId);
  const kpiOptions = kpiOptionsExtractor(teamId);

  useEffect(() => {
    setOptionsLoading(true);
    Promise.all([athleteRoleOptions, categoryOptions, tagOptions, kpiOptions])
      .then(([athleteRoleOptionsValue, categoryOptionsValue, tagOptionsValue, kpiOptionsValue]) => {
        // Extract values from resolved promises:
        setOptions({
          athleteRoles: athleteRoleOptionsValue,
          categories: categoryOptionsValue,
          tags: tagOptionsValue,
          kpis: kpiOptionsValue,
        });
        setTimeout(() => {
          setOptionsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.error('Error fetching options: ', error);
      });
  }, [teamId]);

  // WebSocket messages:
  const { messages } = useContext(WebsocketRoomsContext);
  const statsSessionRowKey = 'ws.statssessionRow';

  // execute ping on New/Add Stats:
  const [pingStats] = useLazyQuery(PING_STATS);

  // how many machines are running: //
  const {
    loading: loadingMachinesCount,
    data: dataMachines,
    refetch: refetchMachinesCount,
  } = useQuery(MACHINES_RUNNING_COUNT, {
    variables: {
      queue: 'gpexe_stats',
    },
  });
  const machinesRunningCount = dataMachines?.res;
  useEffect(() => { // check the machine's status every 15 seconds
    let interval;
    if (trend[teamId]?.loading) {
      interval = setInterval(() => {
        refetchMachinesCount();
      }, 15000);
    }
    return () => clearInterval(interval);
  }, [trend[teamId]?.loading]);

  // check if current statsessionrow is ready (used to prevent WS problems)
  const [checkStatsReady] = useLazyQuery(CHECK_STATS_READY, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data?.res) {
        setTimeout(() => {
          setTrend((prev) => ({
            ...prev,
            [teamId]: {
              ...prev[teamId],
              loading: false,
              newLoading: false,
              addLoading: false,
              ready: true,
            },
          }));
        });
      }
    },
  });

  const [deleteStatsessionRowId] = useMutation(DELETE_STATSESSION_ROW, {
    onCompleted() {
      if (!editSerie) {
        enqueueSnackbar(
          t('', 'stat serie removed successfully'),
          {
            variant: 'success',
            autoHideDuration: SNACKBAR_TIMEOUT,
          },
        );
      }
    },
  });

  /* First query: create statsSessionID and first statSessionRowID (when creating a new trend):
  (created 2 instances to avoid reference/cache problems, the query was called twice when adding a series) */
  const [getTrendIdNew] = useLazyQueryCached(GET_TREND_ID, { fetchPolicy: 'no-cache' });
  const [getTrendIdAdd] = useLazyQueryCached(GET_TREND_ID, { fetchPolicy: 'no-cache' });

  const [createStatsSessionId] = useMutation(STORE_STATISTICS, {
    onCompleted: (res) => {
      setTrend((prev) => ({
        ...prev,
        [teamId]: {
          ...prev[teamId], statsSessionID: res.storeStatistics, ready: false, loading: true, newLoading: true, oldStatsData: false,
        },
      }));
      clearPage();
      if (editSerie) {
        deleteStatsessionRowId({ variables: { statsessionRowId: editSerie } });
        setEditSerie('');
      }
      getTrendIdNew({
        variables: {
          teamId,
          statssessionId: res.storeStatistics,
          startDate: currentValues.startDate,
          endDate: currentValues.endDate,
          period: currentValues.period,
          groupingBy: currentValues.groupingBy === 'no grouping' ? '' : currentValues.groupingBy,
          athleteIds: currentValues?.athletes[0]?.groupBy === 'athlete' ? currentValues?.athletes.map((a) => a.id) : [],
          kpi: currentValues?.KPI ? [currentValues?.KPI[0].id] : [],
          categoryIds: currentValues?.categories?.map((c) => c.id) || [],
          tagsIds: currentValues?.tags?.map((t) => t.id) || [],
          role: currentValues?.athletes[0]?.groupBy === 'role' ? currentValues?.athletes[0].id : null,
          matchDistance: currentValues?.matchDistance && currentValues?.matchDistance.length ? currentValues?.matchDistance[0]?.value : null,
          matchCycle: currentValues?.matchCycle && currentValues?.matchCycle.length ? currentValues?.matchCycle[0]?.value : null,
          chartType: currentValues?.chartType ? currentValues.chartType : null,
          rowNumber: editSerie ? tableRows.findIndex((obj) => obj.statSessionRowId === editSerie) : null,
        },
        onCompleted: (data) => {
          setTrend((prev) => ({ ...prev, [teamId]: { ...prev[teamId], statsSessionRowsID: [data?.res?.trendData] } }));
        },
        onError() {
          enqueueSnackbar(
            t('dialog.general.somethingWentWrong', 'something went wrong'),
            {
              variant: 'error',
              autoHideDuration: SNACKBAR_TIMEOUT,
            },
          );
          resetPageStatus();
        },
      });
    },
  });

  const handleNoData = (type = 'noData') => {
    if (type === 'noData' || type === 'dbError') {
      enqueueSnackbar(
        t('stats.dialog.noData', 'no data available'),
        {
          variant: 'info',
          autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
        },
      );
    } else {
      enqueueSnackbar(t('dialog.general.somethingWentWrong', 'something went wrong'), {
        variant: 'error',
        autoHideDuration: SNACKBAR_TIMEOUT,
      });
    }
    if (trend[teamId]?.statsSessionRowsID.length > 1 && type === 'noData') {
      setTrend((prev) => ({
        ...prev,
        [teamId]: {
          ...prev[teamId],
          statsSessionRowsID: prev[teamId]?.statsSessionRowsID?.slice(0, -1),
          loading: false,
          newLoading: false,
          addLoading: false,
          ready: true,
        },
      }));
    } else {
      resetPageStatus();
      setTimeout(() => window.location.reload(), 1500);
    }
    clearAndRefetchPage();
    setIsChartDataLoading(false);
    setIsLoading(false);
  };

  // Second query: called when statsessionrow is ready (we wait for the websocket message):
  const [getTrendData, { error, variables }] = useLazyQueryCached(GET_TREND_SERIES, {
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      const data = d?.res;
      const trendArray = data?.chartData;
      const queryData = data?.queryDataDict;
      const statSessionRowId = variables?.statssessionRowId;
      const isLastStatsSessionRow = statSessionRowId === trend[teamId]?.statsSessionRowsID?.at(-1);
      const rowNumber = d?.res?.rowNumber;

      if (trendArray) {
        const currentData = trendArray?.map((d, idx) => {
          const tooltipObject = [
            {
              key: t('table.date', 'date'),
              value: d?.xValue,
              valueType: 'date',
            },
            {
              key: d?.athlete ? t('table.athlete', 'athlete') : queryData?.role ? t('athlete.widgets.label.role', 'role') : '',
              value: d?.athlete ? convertToTitle(d?.athlete) : queryData?.role ? queryData?.role.toUpperCase() : '',
            },
            {
              key: t('session.labels.categories', 'categories'),
              value: [d?.categories[0].split(',').join(', ')] || null,
              valueType: 'multi-value',
            },
            {
              key: t(`kpi2.${camelCaser(d?.kpi?.group, d?.kpi?.key)}.label`, d?.kpi?.key.split('_')),
              value: d?.yValue.value,
              unit: d?.yValue.unit,
              uom: getUomTranslation(t, d?.yValue?.uom),
              valueType: 'GenericValueType',
            },
          ] as TooltipKeys[];
          return {
            id: idx,
            x: parseInt(d?.xValue),
            y: d?.yValue?.value,
            name: d?.athlete,
            tooltipText: tooltipFormatter(tooltipObject),
          };
        });

        const serie = {
          type: queryData?.chartType,
          data: currentData,
          categories: [],
          yAxis: `axis-${trendArray[0]?.yValue.unit}`,
          uom: trendArray[0]?.yValue.uom,
        };

        // rowNumber+1 because in 0 index position we have null:
        const updatedSeries = rowNumber !== null
          ? [...currentSeries.slice(0, rowNumber + 1), serie, ...currentSeries.slice(rowNumber + 1)] // If rowNumber is specified, insert serie at the given index
          : [...currentSeries, serie]; // otherwise add it to the end

        setCurrentSeries(updatedSeries);

        setProperties({ title: data?.title, showMyStats: data?.showMyStats, showDashboard: data?.showDashboard });

        if (data?.hasReprocessableAthletesessions && !trend[teamId]?.oldStatsData) {
          setTrend((prev) => ({
            ...prev,
            [teamId]: {
              ...prev[teamId],
              oldStatsData: true,
            },
          }));
        }

        const queryDataFormatted = {
          ...queryData,
          statSessionRowId,
          athletes: options.athleteRoles.filter((option) => queryData?.athletes?.includes(option.id) || queryData?.role === option.id),
          categories: options.categories.filter((option) => queryData?.categories?.includes(option.id)),
          tags: options.tags.filter((option) => queryData?.tags?.includes(option.id)),
          KPI: options.kpis.filter((option) => queryData?.KPI?.includes(option.id)),
          matchDistance: queryData?.matchDistance == null ? null : matchDistanceOptions.filter((op) => op.value === queryData?.matchDistance),
          matchCycle: queryData?.matchCycle == null ? null : matchCycleOptions.filter((op) => op.value === queryData?.matchCycle),
        };

        const updatedCurrentValues = {
          ...queryDataFormatted,
          period: queryDataFormatted.period,
          startDate: queryDataFormatted.startDate,
          endDate: queryDataFormatted.endDate,
          athletes: queryDataFormatted.athletes,
          KPI: queryDataFormatted.KPI,
          categories: queryDataFormatted.categories,
          groupingBy: queryDataFormatted.groupingBy === '' ? 'no grouping' : queryDataFormatted.groupingBy,
          tags: queryDataFormatted.tags,
          matchDistance: queryDataFormatted.matchDistance,
          matchCycle: queryDataFormatted.matchCycle,
          chartType: queryDataFormatted.chartType,
        };

        setAllCurrentValues((prev) => ({ ...prev, [statSessionRowId]: updatedCurrentValues }));

        if (isLastStatsSessionRow) {
          setCurrentValues(updatedCurrentValues);
          // create copy to avoid deleting addseries form data when new is clicked:
          setCurrentValuesCopy(JSON.parse(JSON.stringify(updatedCurrentValues)));
          setIsLoading(false);
        }
        const updatedTableRows = rowNumber !== null
          ? [...tableRows.slice(0, rowNumber), queryDataFormatted, ...tableRows.slice(rowNumber)] // If rowNumber is specified, insert serie at the given index
          : [...tableRows, queryDataFormatted]; // otherwise add it to the end

        setTableRows(updatedTableRows);
      } else {
        handleNoData();
      }
    },
    onError({ message }) {
      if (message === dataDeletedFromDbErr.trim()) {
        handleNoData('dbError');
      } else {
        handleNoData('error');
      }
    },
  });

  // Call getTrendData for each statsessionRowId:
  async function getAllTrendData() {
    const ids = trend[teamId]?.statsSessionRowsID?.sort();
    for (const id of ids) {
      await getTrendData({
        variables: { statssessionRowId: id },
      });
    }
  }

  // Listen to websocket response, if statsessionrow's ready, set ready to true:
  useEffect(() => {
    const lastStatsSessionRowId = trend[teamId]?.statsSessionRowsID?.at(-1) ?? '';
    const statsSessionRowValue = messages[statsSessionRowKey]?.value;

    if (statsSessionRowValue >= lastStatsSessionRowId) {
      setTrend((prev) => ({
        ...prev,
        [teamId]: {
          ...prev[teamId], loading: false, newLoading: false, addLoading: false, ready: true,
        },
      }));
    }
  }, [messages[statsSessionRowKey]]);

  useEffect(() => {
    if (!trend[teamId]?.statsSessionRowsID?.length) {
      resetPageStatus();
    }
    if (trend[teamId]?.ready) {
      clearPage();
      setIsChartDataLoading(true);
      Promise.all([athleteRoleOptions, categoryOptions, tagOptions, kpiOptions])
        .then(() => getAllTrendData().then(() => setIsChartDataLoading(false)));
    }
  }, [trend[teamId]?.ready, refetchStatus]);

  useEffect(() => {
    /* if loading and waiting for series data, check every 10 seconds if data is ready
    (used to prevent WS connection problems) */
    const lastSeriesId = trend[teamId]?.statsSessionRowsID.at(-1);
    if (trend[teamId]?.loading && lastSeriesId) {
      intervalId.current = setInterval(() => {
        checkStatsReady({
          variables: { statssessionRowId: lastSeriesId },
        });
      }, 5000);
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current); // clear interval when component unmounts
        intervalId.current = undefined;
      }
    };
  }, [trend[teamId]?.loading, trend[teamId]?.statsSessionRowsID]);

  const exportData = {
    stats_session: trend[teamId]?.statsSessionID,
    new_web_app: true,
  };

  const clearFields = () => {
    if (addSeries) {
      setCurrentValues({
        ...currentValues,
        athletes: null,
        KPI: null,
        categories: null,
        tags: null,
        chartType: 'column',
      });
    } else {
      setCurrentValues(EmptyTableRow);
    }
  };

  const myStatsDisabled = !allowStats || trend[teamId]?.loading || isLoading || !trend[teamId]?.statsSessionID;

  const headerContext = useContext(AppBarContext);
  useEffect(() => {
    headerContext.setContent(
      <Header
        leftButtons={[
          <BaseButton
            key="newSeries"
            collapseOnMobile
            onClick={() => {
              setCurrentValues(EmptyTableRow);
              setContentOpen((prev) => !prev);
              pingStats();
              setEditSerie('');
            }}
            disabled={!allowStats || (trend[teamId]?.statsSessionID && trend[teamId]?.loading)}
            startIcon={<NewIcon />}
          >
            {t('header.action.new', 'new')}
          </BaseButton>,
          <ExportButton
            collapseOnMobile
            teamId={teamId}
            templateId={templateId}
            filename="gpexe_trend"
            disable={!allowStats || currentSeries.length === 1 || trend[teamId]?.loading}
            exportData={[
              { format: 'pdf', label: t('exports.type.pdf', 'PDF'), data: exportData },
              { format: 'xlsx', label: t('exports.type.standardXls', 'XLS'), data: exportData },
              { format: 'csv', label: t('exports.type.csv', 'CSV'), data: exportData },
            ]}
            target="Trend"
          />,
          <BaseButton
            key="myStats"
            collapseOnMobile
            onClick={() => {
              setOpenAddToMyStats((prev) => !prev);
              pingStats();
            }}
            disabled={!allowStats || myStatsDisabled || isChartDataLoading || trend[teamId]?.loading || !trend[teamId]?.statsSessionID}
            startIcon={<StatsIcon fill={myStatsDisabled || isChartDataLoading || trend[teamId]?.loading ? 'var(--stroke-gray)' : 'var(--secondary-color)'} />}
          >
            {t('stats.drawer.properties', 'properties')}
          </BaseButton>,
        ]}
      />,
    );
  }, [teamId, headerContext.setContent, contentOpen, allowStats, currentSeries, trend[teamId]?.loading, trend[teamId]?.statsSessionID, isChartDataLoading]);

  const loading = options?.athleteRoles?.length === 0
  && options?.categories?.length === 0
  && options?.tags?.length === 0
  && options?.kpis?.length === 0
  && loadingCount
  && loadingMachinesCount;

  useEffect(() => {
    const loadingState = trend[teamId]?.addLoading || loading;
    setIsLoading(loadingState);
    return () => {
      setIsLoading(false); // Set the loading state to false when the component unmounts
    };
  }, [trend[teamId]?.addLoading, loading]);

  if (error) return <Error />;
  if (loading) return '';

  return (
    <Box>
      <TitleView title={properties?.title || t('sidebar.route.trend', 'trend')} isPageTitle />
      {loading ? (
        null
      )
        : trend[teamId]?.newLoading
          ? (
            machinesRunningCount === 0
              ? (
                <PageMessage
                  iconName="rocket"
                  title={t('stats.paper.warmingUpEngines', 'warming up the engines of the stats server!')}
                  text={t('stats.paper.waitMinutes', 'Please wait a couple of minutes before starting the data processing.')}
                />
              )
              : (
                <PageMessage
                  iconName="hourglass"
                  title={t('stats.dialog.processing', 'data processing in progress')}
                  text={t('stats.dialog.waiting', 'Waiting for the latest submitted series.')}
                />
              )
          )
          : !allowStats ? (
            <PageMessage
              iconName="info"
              title={t('stats.paper.noSessions', 'no sessions')}
              text={t('stats.paper.createSession', 'Create your first session to start using the statistical features.')}
            />
          )
            : (allowStats && trend[teamId]?.statsSessionRowsID?.length === 0) ? (
              <PageMessage
                iconName="info"
                title={t('stats.type.trend.noData', 'no trend data')}
                text={t('stats.paper.createStatistic', 'Create your first statistic.')}
              />
            )
              : (
                <Row>
                  {
                    trend[teamId]?.oldStatsData && (
                      <Column sm={12}>
                        <PageMessage
                          iconName="alert"
                          title={t(
                            'track.tabs.needReprocess.stats.title',
                            'Graph data may be inconsistent.',
                          )}
                          text={t(
                            'track.tabs.needReprocess.stats.content',
                            'Wait for the ongoing reprocessing triggered by a threshold change to finish.',
                          )}
                          noMargins
                        />
                      </Column>
                    )
                  }
                  <Column xs={12}>
                    <Paper style={{ minHeight: '742px' }}>
                      <Box
                        sx={{
                          height: 60,
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center',
                        }}
                      >
                        {currentSeries.length > 1 && (
                          <div style={{ width: 'fit-content' }}>
                            <BaseButton
                              key="copy-link"
                              className="copy-link-button"
                              buttonSize="sm"
                              startIcon={<CopyIcon />}
                              onClick={() => {
                                const url = copyStatsUrl(teamId, 'trend', trend[teamId]?.statsSessionID, userId);
                                navigator.clipboard.writeText(url).then(
                                  () => {
                                    enqueueSnackbar(t('files.dialog.linkCopied', 'link successfully copied'), {
                                      variant: 'success',
                                      autoHideDuration: SNACKBAR_TIMEOUT,
                                    });
                                  },
                                );
                              }}
                            >
                              {t('files.copyLink', 'copy link')}
                            </BaseButton>
                            <BaseButton
                              key="addSeries"
                              buttonSize="sm"
                              marginRight
                              startIcon={<AddIcon />}
                              disabled={isChartDataLoading || trend[teamId]?.loading}
                              onClick={() => {
                                setContentOpen(true);
                                setCurrentValues(JSON.parse(JSON.stringify(currentValuesCopy)));
                                setAddSeries(true);
                                pingStats();
                              }}
                            >
                              {t('stats.drawer.button.addSeries', 'add series')}
                            </BaseButton>
                          </div>
                        )}
                      </Box>
                      <ColumnChart
                        xAxysType="datetime"
                        type="stats"
                        data={chartData}
                        rotateXLabel={-45}
                        loading={isChartDataLoading}
                        formOptions={currentValuesCopy}
                      />
                    </Paper>
                    {allowStats && (
                    <Box mt={3}>
                      <SeriesTable
                        teamId={teamId}
                        updateLocalStorage={setTrend}
                        setRefetchStatus={setRefetchStatus}
                        setMyStats={setMyStats}
                        tableRows={isChartDataLoading ? [] : tableRows}
                        statsType="trend"
                        setEditSerie={setEditSerie}
                        setContentOpen={setContentOpen}
                        deleteStatsessionRowId={deleteStatsessionRowId}
                        allCurrentValues={allCurrentValues}
                        setCurrentValues={setCurrentValues}
                      />
                    </Box>
                    )}
                  </Column>
                </Row>
              )}
      <SideDrawer
        open={contentOpen && allowStats}
        setOpen={setContentOpen}
        title={editSerie ? t('', 'edit trend') : addSeries ? t('stats.drawer.addTrend', 'add trend')
          : t('stats.drawer.newTrend', 'new trend')}
        actionsLeft={[
          <BaseButton
            key="cancel"
            startIcon={<CancelIcon />}
            onClick={() => {
              setContentOpen(false);
              setAddSeries(false);
            }}
            noMargins
          >
            {t('stats.drawer.button.cancel', 'cancel')}
          </BaseButton>,

          <BaseButton key="clearAll" startIcon={<ClearAllIcon />} onClick={() => clearFields()}>
            {t('stats.drawer.button.clearAll', 'clear all')}
          </BaseButton>,
        ]}
        actionsRight={[
          <BaseButton
            key="add"
            color="primary"
            startIcon={<SubmitIcon />}
            disabled={disabledButton}
            isValidation={!disabledButton}
            marginRight
            onClick={() => {
              // new statssession || edit request with only 1 serie available:
              if ((!addSeries && !editSerie) || (editSerie && trend[teamId]?.statsSessionRowsID.length === 1)) {
                setProperties({ ...properties, title: '' });
                createStatsSessionId({
                  variables: {
                    statsType: 'trend',
                    teamId,
                  },
                });
              } else {
                if (editSerie) {
                  deleteStatsessionRowId({ variables: { statsessionRowId: editSerie } });
                }
                getTrendIdAdd({
                  variables: {
                    statssessionId: trend[teamId]?.statsSessionID,
                    startDate: currentValues.startDate,
                    endDate: currentValues.endDate,
                    period: currentValues.period,
                    groupingBy: currentValues.groupingBy,
                    athleteIds:
                      currentValues?.athletes[0]?.groupBy === 'athlete' ? currentValues?.athletes.map((a) => a.id) : [],
                    kpi: currentValues?.KPI ? [currentValues?.KPI[0].id] : [],
                    categoryIds: currentValues?.categories?.map((c) => c.id) || [],
                    tagsIds: currentValues?.tags?.map((t) => t.id) || [],
                    teamId,
                    role: currentValues?.athletes[0]?.groupBy === 'role' ? currentValues?.athletes[0].id : null,
                    matchDistance: currentValues?.matchDistance && currentValues?.matchDistance.length ? currentValues?.matchDistance[0]?.value : null,
                    matchCycle: currentValues?.matchCycle && currentValues?.matchCycle.length ? currentValues?.matchCycle[0]?.value : null,
                    chartType: currentValues?.chartType ? currentValues.chartType : null,
                    rowNumber: editSerie ? tableRows.findIndex((obj) => obj?.statSessionRowId === editSerie) : null,
                  },
                  onCompleted: (data) => {
                    if (editSerie) {
                      setTrend((prev) => {
                        // remove statsessionrowid from localStorage:
                        const updatedStatsSessionRowsID = prev[teamId].statsSessionRowsID.filter(
                          (id) => id !== editSerie,
                        );
                        return {
                          ...prev,
                          [teamId]: {
                            ...prev[teamId],
                            loading: true,
                            addLoading: true,
                            ready: false,
                            statsSessionRowsID: [...updatedStatsSessionRowsID, data?.res?.trendData],
                          },
                        };
                      });
                    } else {
                      setTrend((prev) => ({
                        ...prev,
                        [teamId]: {
                          ...prev[teamId],
                          loading: true,
                          addLoading: true,
                          ready: false,
                          statsSessionRowsID: [...trend[teamId]?.statsSessionRowsID, data?.res?.trendData],
                        },
                      }));
                    }
                    setMyStats((prev) => ({
                      ...prev,
                      refetchMyStatsStatus: prev.refetchMyStatsStatus + 1,
                    }));
                    enqueueSnackbar(t('stats.dialog.addSeries', 'series added to processing'), {
                      variant: 'success',
                      autoHideDuration: SNACKBAR_TIMEOUT,
                    });
                    setEditSerie('');
                  },
                });
              }
              setAddSeries(false);
              setContentOpen(false);
            }}
            noMargins
          >
            {t('header.action.submit', 'submit')}
          </BaseButton>,
        ]}
      >
        {optionsLoading
          ? <Loader />
          : (
            <SeriesInputs2
              currentValues={currentValues}
              setCurrentValues={setCurrentValues}
              addSeries={addSeries || !!editSerie}
              statsType="trend"
              barLine
              allowAthleteMultivalue={false}
              setdisabledButton={setdisabledButton}
              athleteOptions={options.athleteRoles}
              categoryOptions={options.categories}
              tagOptions={options.tags}
              kpiOptions={options.kpis}
            />
          )}
      </SideDrawer>
      <AddToMyStatsDrawer
        statsSessionId={trend[teamId]?.statsSessionID}
        openAddToMyStats={openAddToMyStats}
        setOpenAddToMyStats={setOpenAddToMyStats}
        properties={properties}
        setProperties={setProperties}
        teamId={teamId}
      />
    </Box>
  );
}
