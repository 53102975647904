import React, { useState } from "react";
import {
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import UserPool from "./UserPool"; // Configurazione della User Pool
import CLIENT_SETTINGS from './lib/client_settings';

export default function CognitoLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState(null);
  console.log(CLIENT_SETTINGS.public.useCognito, " cognito");

  const handleSubmit = (e) => {
    e.preventDefault();

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        localStorage.setItem('exelio_cognito_token', data.getAccessToken().getJwtToken());
        localStorage.setItem('exelio_cognito_refresh_token', data.getRefreshToken().getToken());
        window.location.href = 'http://localhost:8080';
      },
      onFailure: (err) => {
        console.error("Error:", err.message || JSON.stringify(err));
        setError(err.message || "Errore durante l'autenticazione.");
      },
      newPasswordRequired: (data) => {
        console.log("New password required:", data);
        setError("È richiesta una nuova password.");
      },
    });
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", padding: "1em" }}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ width: "100%", padding: "0.5em", margin: "0.5em 0" }}
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ width: "100%", padding: "0.5em", margin: "0.5em 0" }}
          />
        </div>
        <button type="submit" style={{ padding: "0.5em 1em", marginTop: "1em" }}>
          Accedi
        </button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {token && (
        <div>
          <h4>Access Token:</h4>
          <p style={{ wordWrap: "break-word" }}>{token}</p>
        </div>
      )}
    </div>
  );
};

