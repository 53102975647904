import React, { useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import DashboardIcon from '@images/IconSidebar/dashboard.svg';
import CalendarIcon from '@images/IconSidebar/calendar.svg';
import AthletesIcon from '@images/IconSidebar/athlete.svg';
import SessionIcon from '@images/IconSidebar/session.svg';
import AthSessionsIcon from '@images/IconSidebar/details.svg';
import LineIcon from '@images/IconSidebar/line.svg';
import StatsIcon from '@images/IconSidebar/stats.svg';
import ProfilesIcon from '@images/IconSidebar/profiles.svg';
import DataSourceIcon from '@images/IconSidebar/datasource.svg';
import SettingsIcon from '@images/IconSidebar/settings.svg';
import XIcon from '@images/IconSidebar/X.svg';
import GpexeIcon from '@images/IconSidebar/gpexe.svg';
import { MeType } from 'models/me';
import { Box } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { WebsocketRoomsContext } from 'Authenticator';
import DevBar from './DevBar';
import { camelCaser } from './utils/kpiLabelExtractor';
import ACLWrapper, { UserContext } from './ACLWrapper';

const linkActiveStatus = (classProps: {isActive: boolean}, forceActive?: boolean) => `link-status ${
  forceActive || classProps.isActive ? 'link-is-active' : ''
}`;

const dashboardIcons = {
  dashboard: <DashboardIcon />,
  calendar: <CalendarIcon />,
  athletes: <AthletesIcon />,
  sessions: <SessionIcon />,
  athSessions: <AthSessionsIcon />,
  line: <LineIcon />,
  stats: <StatsIcon />,
  profiles: <ProfilesIcon />,
  dataSource: <DataSourceIcon />,
  settings: <SettingsIcon />,
};

const SidebarNavLink = React.memo((props: {
  open: boolean,
  name: string,
  icon?: React.ReactElement,
  endsWith: string | string[],
  linkTo: string,
  labelI18n: string,
  inner?: boolean,
}) => {
  const {
    endsWith, icon, inner, open, labelI18n, linkTo, name,
  } = props;
  const { t } = useTranslation();
  const label = t(labelI18n, { defaultValue: name });

  const component = icon || dashboardIcons[camelCaser(name)] || dashboardIcons.line;
  const pathEndsWith = (path: string, endString: string | string[]) => {
    if (typeof endString === 'string') {
      return (new RegExp(`/${endString}(?:/?.*)$`)).test(path);
    }

    let status = false;
    endString.forEach((str) => {
      if ((new RegExp(`/${str}(?:/?.*)$`)).test(path)) {
        status = true;
      }
    });

    return status;
  };

  return (
    <NavLink
      to={linkTo}
      className={(classProps) => linkActiveStatus(
        classProps,
        pathEndsWith(location.pathname, endsWith),
      )}
    >
      {
      !inner
      && (
      <div className={`navlink--inner-${inner ? '1' : '0'} icon-wrapper`}>
        <Tooltip
          arrow
          placement="right"
          title={open ? '' : (label || '')}
        >
          <div>{component}</div>
        </Tooltip>
      </div>
      )
    }
      <span
        data-i18n={labelI18n}
        className={`${inner ? 'inner' : ''}`}
        style={{ marginLeft: '1.1rem' }}
      >
        {t(labelI18n, { defaultValue: label })}
      </span>
    </NavLink>
  );
});

const Summary = React.memo((props: {
  isActive: boolean,
  isOpen: boolean,
  closedLabelI18n: string,
  openedLabelI18n: string,
  name: string,
  icon?: React.ReactElement,
}) => {
  const {
    closedLabelI18n, openedLabelI18n, isActive, isOpen, name, icon,
  } = props;
  const { t } = useTranslation();

  return (
    <summary
      aria-controls="panel1a-content"
      className={`${isActive ? 'parent-is-active' : ''} group`}
      id="panel-stats"
    >
      <div>
        <div className="icon-wrapper">
          <Tooltip arrow placement="right" title={isActive ? '' : t(closedLabelI18n, { defaultValue: name })}>
            <div>{icon || dashboardIcons[name] || dashboardIcons.line}</div>
          </Tooltip>
        </div>
        {isOpen && (
        <span className="label-wrapper">
          { t(openedLabelI18n, { defaultValue: name }) }
        </span>
        )}
      </div>
      {isOpen && (
      <span style={{ paddingRight: '8px' }}>
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.24537e-07 -1.35106e-06L5 6L10 -4.76837e-07L5.24537e-07 -1.35106e-06Z" fill="#BDBDBD" />
        </svg>
      </span>
      )}
    </summary>
  );
});

const Sidebar = React.memo((props: {open: boolean, me: MeType, toggleDrawer: () => void;}) => {
  const location = useLocation();
  const [currentSection, setCurrentSection] = React.useState(window.location.pathname);
  const { open, me, toggleDrawer } = props;
  const { userCan } = React.useContext(UserContext);
  const navigate = useNavigate();
  const { messages } = useContext(WebsocketRoomsContext);

  useEffect(() => {
    let activeSection;

    const matchRestPattern = (prefix) => new RegExp(`${prefix}(s|\\/[0-9]+)?$`);
    const matchUrlSuffix = (suffix) => matchRestPattern(suffix).test(location.pathname);

    switch (true) {
      case matchUrlSuffix('trend'):
      case matchUrlSuffix('summary'):
      case matchUrlSuffix('radar'):
        activeSection = 'stats';
        break;

      case matchUrlSuffix('as_profiles'):
      case matchUrlSuffix('mp_profiles'):
      case matchUrlSuffix('comparison'):
        activeSection = 'profiles';
        break;

      case matchUrlSuffix('files'):
      case matchUrlSuffix('tracks'):
        activeSection = 'dataSource';
        break;

      case matchUrlSuffix('newTemplate'):
      case matchUrlSuffix('templates'):
      case matchUrlSuffix('teams'):
      case matchUrlSuffix('users'):
      case matchUrlSuffix('grounds'):
      case matchUrlSuffix('club'):
        activeSection = 'settings';
        break;

      default:
        activeSection = '';
        break;
    }

    setCurrentSection(activeSection);
  }, [location.pathname]);

  const handleSectionClick = (section: string, e: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (currentSection === section) {
      setCurrentSection('');
    } else {
      setCurrentSection(section);
    }
  };

  return (
    <div className={`${open ? 'sidebar-open' : 'sidebar-closed'} left-drawer h-screen fixed`}>
      <div className="left-drawer__outer">
        <Box>
          {/* LOGO X */}
          <Box onClick={() => navigate('/dashboard')} className="pointer">
            <XIcon />
          </Box>
          {open && (
            <Box onClick={() => navigate('/dashboard')} className="gpexe-icon pointer">
              <GpexeIcon />
            </Box>
          )}
        </Box>
      </div>
      {/* space for logo */}
      <div className="sidebar-links-wrapper left-drawer__links">

        {/* DASHBOARD */}
        <ACLWrapper section="dashboard" type="view" permission="page_view">
          <SidebarNavLink
            open={open}
            linkTo="/dashboard"
            labelI18n="sidebar.route.dashboard"
            name="dashboard"
            endsWith="dashboard"
          />
        </ACLWrapper>
        {/* CALENDAR */}
        <ACLWrapper section="calendar" type="view" permission="page_view">
          <SidebarNavLink
            open={open}
            linkTo="/calendar"
            labelI18n="sidebar.route.calendar"
            name="calendar"
            endsWith="calendar"
          />
        </ACLWrapper>

        {/* ATHLETES */}
        <SidebarNavLink
          open={open}
          linkTo="/athletes"
          labelI18n="sidebar.route.athletes"
          name="athletes"
          endsWith="athletes"
        />

        {/* SESSIONS */}
        <ACLWrapper section="sessions" type="view" permission="page_view">
          <SidebarNavLink
            open={open}
            linkTo="/sessions"
            labelI18n="sidebar.route.sessions"
            name="sessions"
            endsWith="sessions"
          />
        </ACLWrapper>

        {/* ATH SESSIONS */}
        <SidebarNavLink
          open={open}
          linkTo="/athlete_sessions"
          labelI18n="sidebar.route.athSessions"
          name="ath sessions"
          endsWith="athlete_sessions"
        />

        {/* STATS */}
        {
          (userCan({ section: 'trend', type: 'view', permission: 'page_view' })
          || userCan({ section: 'summary', type: 'view', permission: 'page_view' })
          || userCan({ section: 'radar', type: 'view', permission: 'page_view' }))
          && (
            <div
              role="menuitem"
              className={`${currentSection === 'stats' ? 'open' : ''} dropdown-link`}
              onClick={(e) => handleSectionClick('stats', e)}
            >
              <Summary
                closedLabelI18n="sidebar.route.tooltip.statsClosed"
                openedLabelI18n="sidebar.route.tooltip.statsOpened"
                isActive={currentSection === 'stats'}
                isOpen={open}
                name="stats"
              />

              <div className="details-content">
                <ACLWrapper section="trend" type="view" permission="page_view">
                  <SidebarNavLink
                    open={open}
                    linkTo="/trend"
                    labelI18n="sidebar.route.trend"
                    name="trend"
                    endsWith="trend"
                    inner
                  />
                </ACLWrapper>
                <ACLWrapper section="summary" type="view" permission="page_view">
                  <SidebarNavLink
                    open={open}
                    linkTo="/summary"
                    labelI18n="sidebar.route.summary"
                    name="summary"
                    endsWith="summary"
                    inner
                  />
                </ACLWrapper>
                <ACLWrapper section="radar" type="view" permission="page_view">
                  <SidebarNavLink
                    open={open}
                    linkTo="/radar"
                    labelI18n="sidebar.route.radar"
                    name="radar"
                    endsWith="radar"
                    inner
                  />
                </ACLWrapper>
              </div>
            </div>
          )
        }
        {/* PROFILES */}
        {!me?.isLastTeamGk
          && (userCan({ section: 'as_profiles', type: 'view', permission: 'page_view' })
          || userCan({ section: 'mp_profiles', type: 'view', permission: 'page_view' })
          || userCan({ section: 'comparison', type: 'view', permission: 'page_view' }))
          && (
            <div
              role="menuitem"
              className={`${currentSection === 'profiles' ? 'open' : ''} dropdown-link`}
              onClick={(e) => handleSectionClick('profiles', e)}
            >
              <Summary
                closedLabelI18n="sidebar.route.tooltip.profilesClosed"
                openedLabelI18n="sidebar.route.tooltip.profilesOpened"
                isActive={currentSection === 'profiles'}
                isOpen={open}
                name="profiles"
              />
              <div className="details-content">
                <ACLWrapper section="as_profiles" type="view" permission="page_view">
                  <SidebarNavLink
                    open={open}
                    linkTo="/as_profiles"
                    labelI18n="sidebar.route.asp"
                    name="ASP"
                    endsWith="asp"
                    inner
                  />
                </ACLWrapper>
                <ACLWrapper section="mp_profiles" type="view" permission="page_view">
                  <SidebarNavLink
                    open={open}
                    linkTo="/mp_profiles"
                    labelI18n="sidebar.route.mpp"
                    name="mpp"
                    endsWith="mpp"
                    inner
                  />
                </ACLWrapper>
                <ACLWrapper section="comparison" type="view" permission="page_view">
                  <SidebarNavLink
                    open={open}
                    linkTo="/comparison"
                    labelI18n="sidebar.route.comparison"
                    name="comparison"
                    endsWith="comparison"
                    inner
                  />
                </ACLWrapper>
              </div>
            </div>
          )}

        {/* TRACKS */}
        <div
          role="menuitem"
          className={`${currentSection === 'dataSource' ? 'open' : ''} dropdown-link`}
          onClick={(e) => handleSectionClick('dataSource', e)}
        >
          <Summary
            closedLabelI18n="sidebar.route.tooltip.dataSourceClosed"
            openedLabelI18n="sidebar.route.tooltip.dataSourceOpened"
            isActive={currentSection === 'dataSource'}
            isOpen={open}
            name="dataSource"
          />

          <div className="details-content">
            <ACLWrapper section="files" type="view" permission="page_view">
              <SidebarNavLink
                open={open}
                linkTo="/files"
                labelI18n="sidebar.route.files"
                name="files"
                endsWith="files"
                inner
              />
            </ACLWrapper>
            <SidebarNavLink
              open={open}
              linkTo="/tracks"
              labelI18n="sidebar.route.tracks"
              name="tracks"
              endsWith="tracks"
              inner
            />
          </div>
        </div>

        {/* SETTINGS */}
        <div
          role="menuitem"
          className={`${currentSection === 'settings' ? 'open' : ''} dropdown-link`}
          onClick={(e) => handleSectionClick('settings', e)}
        >
          <Summary
            closedLabelI18n="sidebar.route.tooltip.settingsClosed"
            openedLabelI18n="sidebar.route.tooltip.settingsOpened"
            isActive={currentSection === 'settings'}
            isOpen={open}
            name="settings"
          />

          <div className="details-content">
            { me?.isStaff &&
          <SidebarNavLink
              open={open}
              linkTo="/targets"
              labelI18n="targets.targets"
              name="targets"
              endsWith="targets"
              inner
            />
        }
            <SidebarNavLink
              open={open}
              linkTo="/templates"
              labelI18n="sidebar.route.templates"
              name="templates"
              endsWith="templates"
              inner
            />
            <ACLWrapper section="teams" type="view" permission="page_view">
              <SidebarNavLink
                open={open}
                linkTo="/teams"
                labelI18n="sidebar.route.teams"
                name="teams"
                endsWith="teams"
                inner
              />
            </ACLWrapper>
            <ACLWrapper section="users" type="view" permission="page_view">
              <SidebarNavLink
                open={open}
                linkTo="/users"
                labelI18n="sidebar.route.users"
                name="users"
                endsWith="users"
                inner
              />
            </ACLWrapper>
            <ACLWrapper section="grounds" type="view" permission="page_view">
              <SidebarNavLink
                open={open}
                linkTo="/grounds"
                labelI18n="sidebar.route.grounds"
                name="grounds"
                endsWith="grounds"
                inner
              />
            </ACLWrapper>
            <ACLWrapper section="club" type="view" permission="page_view">
              <SidebarNavLink
                open={open}
                linkTo="/club"
                labelI18n="sidebar.route.club"
                name="club"
                endsWith="club"
                inner
              />
            </ACLWrapper>
          </div>
        </div>
      </div>

      <DevBar />
    </div>
  );
});

export default Sidebar;
